import { StoredFile } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../index';

export type ConfirmFileUploadResponse = StoredFile;

const confirmFileUpload = async (
  sponsorshipApplicationId: string,
  filePath: string,
  fileType: string,
): Promise<ConfirmFileUploadResponse> => {
  const response = await backendAuthenticatedAxios.post<ConfirmFileUploadResponse>(
    `/sponsorship-applications/${sponsorshipApplicationId}/confirm-file-upload`,
    { file_path: filePath, file_type: fileType },
  );

  return response.data;
};

export default confirmFileUpload;
