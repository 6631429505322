import { SponsorshipApplicationFiles } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const submitFiles = async (sponsorshipApplicationId: string, files: FormData): Promise<AxiosResponse<SponsorshipApplicationFiles>> => {
  return backendAuthenticatedAxios.post(`sponsorship-applications/${sponsorshipApplicationId}/submit-files`, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 180000, // Set timeout to 180 seconds (180000 milliseconds)
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      console.log(`Upload progress: ${percentCompleted}%`);
    },
  });
};

export default submitFiles;
