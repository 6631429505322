import React, { FunctionComponent, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import useAlert from '../../../hooks/useAlert';
import backend from '../../../lib/backend';
import repaymentActiveModal from '../../../state/atoms/repaymentModalOpen';
import { useUser } from '../../../state/user';
import { MethodFIRepaymentModalSteps } from '../../../types/methodFiModals';

import { AxiosError } from 'axios';

const MethodFiManageLoanAccounts: FunctionComponent = () => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [ loading, setLoading ] = useState(false);
  const user = useUser();

  const setRepaymentActiveModal = useSetRecoilState(repaymentActiveModal);

  const dropRepaymentConsent = useCallback(async () => {
    setLoading(true);

    try {
      await backend.withdrawMethodConsent();
      user.refresh();
      showSuccessAlert('Successfully removed consent from Method entities.');
      setRepaymentActiveModal(null);
    } catch (error) {
      const axiosError = error as AxiosError<string>;
      console.error(axiosError);
      setLoading(false); // No need to set loading to false in a finally block since we are dismissing the modal on success
      const errorMessage = axiosError.response?.data || 'Something went wrong while removing consent from Method entities, please try again later.';
      showErrorAlert(errorMessage);
    }
  }, [ setRepaymentActiveModal, showErrorAlert, showSuccessAlert, user ]);

  return (
    <Dialog id='manage-loan-accounts-dialog' onClose={() => setRepaymentActiveModal(null)} open={true}>
      <Box mx={3} my={3}>
        <DialogTitle>Update Connected Student Loan Accounts</DialogTitle>
        <DialogContent>
          Please note, at least one connected student loan account is required to facilitate the benefits outlined in your repayment agreement.
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='space-evenly' width='100%'>
            <Button
              disabled={loading}
              id='manage-loan-accounts-dialog-remove-action'
              onClick={dropRepaymentConsent}
              size='small'
              sx={{ m: 1 }}
              variant='outlined'
            >
              Remove all connected accounts
            </Button>
            <Button
              autoFocus
              disabled={loading}
              id='manage-loan-accounts-dialog-view-action'
              onClick={() => setRepaymentActiveModal(MethodFIRepaymentModalSteps.METHODFI_ELEMENT)}
              size='small'
              sx={{ m: 1 }}
              variant='contained'
            >
              View connected accounts
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default MethodFiManageLoanAccounts;
