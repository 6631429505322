import axios from 'axios';

const pushFileToSignedUrl = async (signedUrl: string, file: File): Promise<void> => {
  await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': 'application/octet-stream', // TODO support correct MIME type
    },
  });
};

export default pushFileToSignedUrl;
