import { ApplicationFormAnswers } from '../../../types/applicationAnswer';
import { StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../';

import { AxiosResponse } from 'axios';

interface SubmitAnswersParams {
    sponsorshipApplicationId: string;
    answers: ApplicationFormAnswers;
    notes: string;
}

const submitAnswers = async ({ sponsorshipApplicationId, answers, notes }: SubmitAnswersParams): Promise<AxiosResponse<StudentSponsorshipApplication>> => {
  const requestData = { answers, notes };

  return backendAuthenticatedAxios.post(`/esl-student/${sponsorshipApplicationId}/submit-answers`, requestData);
};

export default submitAnswers;
