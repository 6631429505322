import { ChangeRequestChangeTypeSpecificData } from '../../../types/changeRequestForm';
import { Disbursement } from '../../../types/disbursement';
import { PortalChangeRequest } from '../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type CreateNewChangeRequestProps = {
  studentEmail: string;
  originatorApplicationId: string;
  studentFullName: string;
  studentCertified: boolean;
  selectedDisbursement: Disbursement | null;
  changeRequestChangeTypeSpecificData: Partial<ChangeRequestChangeTypeSpecificData>;
};

interface CreateNewChangeRequestResponse {
    detail: string;
    change_requests: PortalChangeRequest[];
}

const createNewChangeRequest = async (
  {
    studentEmail,
    originatorApplicationId,
    studentFullName,
    studentCertified,
    selectedDisbursement,
    changeRequestChangeTypeSpecificData,
  }: CreateNewChangeRequestProps,
): Promise<AxiosResponse<CreateNewChangeRequestResponse>> => {
  const requestData = {
    'student_email': studentEmail,
    'originator_app_id': originatorApplicationId,
    'student_full_name': studentFullName,
    'student_certified': studentCertified,
    'disbursement_id': selectedDisbursement?.id,
    'change_requests_change_type_specific_data': changeRequestChangeTypeSpecificData,
  };

  return backendAuthenticatedAxios.post<CreateNewChangeRequestResponse>(
    '/change-request/create', requestData,
  );
};

export default createNewChangeRequest;
