import { StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../';

import { AxiosResponse } from 'axios';

interface SubmitLocationPreferencesParams {
    sponsorshipApplicationId: string;
    locationPreferences: string[];
}

const submitLocationPreferences = async ({ sponsorshipApplicationId, locationPreferences }: SubmitLocationPreferencesParams): Promise<AxiosResponse<StudentSponsorshipApplication>> => {
  const requestData = { location_preferences: locationPreferences };

  return backendAuthenticatedAxios.post(`/esl-student/${sponsorshipApplicationId}/submit-location-preferences`, requestData);
};

export default submitLocationPreferences;
